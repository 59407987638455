import React, {Component} from 'react'
import styled from 'styled-components'
import {Form, Alert} from 'react-bootstrap';
import CTAButton from '../utils/CTAButton'
import { forgotPassword, isLoggedIn, isBrowser } from "../User/User"

const StyledForm = styled(Form)`
  font-size: 1.25rem;
`

export default class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      alert: null
    }
  }
    
  handleSubmit = event => {
    event.preventDefault();
    forgotPassword(this.state.email).then( (response) => {
      if (response.result == "OK") {
        this.setState({'alert': {
          type: "success",
          message: response.message
        }})
      } else {
        this.setState({'alert': {
          type: "danger",
          message: "Error: " + response.message
        }})
      }
    });
  }
  
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  
  clearForm = () => {
    this.setState({
      email: '',
      alert: null
    });
  }

  render() {
    let alert;
    if (this.state.alert) {
      alert = <Alert variant={this.state.alert.type}>{this.state.alert.message}</Alert>
    }
    
    return (
      <StyledForm name="forgotPassword" onSubmit={this.handleSubmit} >
        <p className="">Enter your email address and we'll send you an email with instructions to reset your password.</p>
        {alert}
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            name="email"
            type="email"
            placeholder="Enter email"
            value={this.state.email}
            onChange={this.handleInputChange}
            required
          />
        </Form.Group>
        <div className="float-right">
          <CTAButton variant="solid-white" size="small" type="submit">Reset</CTAButton>
        </div>
      </StyledForm>
    )
  }
}
